.pendingTask,
.completedTask,
.failedTask,
.verifyingTask {
  min-height: 48px;
  width: 100%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .leftIcon {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .titleContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    width: 100%;
    h2 {
      font-size: 16px;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;
      flex-grow: 1;
      word-break: break-word;
    }
    .messageContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;

      p {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #000000;
        opacity: 60%;
      }
    }
    .errorMessageContainer {
      @extend .messageContainer;
      .errorLink {
        color: #3bb3e5;
        text-decoration: underline;
      }
    }
    .statusMessageContainer {
      @extend .messageContainer;
    }
  }
}

.pendingTask,
.failedTask,
.verifyingTask {
  border: 1px solid rgb(225, 232, 240);

  .arrowRight {
    margin-left: auto;
    margin-right: 20px;
  }
}

.failedTask,
.completedTask,
.verifyingTask {
  .arrowRight {
    display: none;
  }
}
.completedTask {
  border: 1px solid #4eb486;
  background-color: #4eb486;

  h2 {
    color: white;
  }
}

.failedTask {
  border: 1px solid red;
}

@media screen and (min-height: 600px) {
  .pendingTask,
  .completedTask,
  .failedTask {
    margin-bottom: 12px;
  }
}

@media screen and (min-height: 800px) {
  .pendingTask,
  .completedTask,
  .failedTask {
    margin-bottom: 16px;
  }
}
