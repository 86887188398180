.mainContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .headerContainer,
  .headerCentered {
    padding: 20px 20px 0px 20px;
    .bottomBorder {
      border-bottom: 1px solid #dcdcdc;
    }
    flex-grow: 0;
    .header {
      .logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .title {
        h1 {
          font-weight: 400;
          margin-top: 20px;
        }
        p {
          color: #dcdcdc;
        }
      }
    }
  }

  .headerCentered {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none;
    .header {
      width: 100%;
      .logo {
        display: flex;
        align-items: center;
        width: 100%;

        img {
          margin: auto;
        }
      }

      .title {
        text-align: center;
        margin-top: 30px;

        h1,
        p {
          margin-top: 0;
          margin-bottom: 0;
          word-wrap: break-word;
        }
      }
    }
  }

  .bodyContainer {
    flex-grow: 1;
  }

  .footerContainer {
    flex-grow: 0;
    position: sticky;
    bottom: 0;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: white;
    box-shadow: 0 -2px 4px 0 rgba(229, 229, 229, 0.5);
    button {
      min-height: 50px;
    }
  }
}
