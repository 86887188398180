.container {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  .fileCardContainer {
    width: 100%;
    height: 64px;
    padding: 16px;
    border: 1px solid #e1e8f0;
    border-radius: 3px;
    display: flex;
    align-items: center;

    .detailContainer {
      margin-left: 16px;
      .title,
      .fileSize {
        font-size: 14px;
        color: #000000;
        max-width: 20ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .fileSize {
        opacity: 30%;
      }
    }

    .actionIconContainer {
      margin-left: auto;
      margin-right: 10px;
      .downloadIcon {
        margin-right: 30px;
      }
    }
  }

  .cancelIcon {
    margin-left: 20px;
  }
}
