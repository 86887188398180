label {
    display: block;
    color: black;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}
.inputContainer {
    width: 100%;
    border: #F4F4F4;
    color: black;
    background-color: #F4F4F4;
}