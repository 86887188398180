.connectedList {
  margin-top: 24px;
  ul {
    padding-left: 0px;
    list-style: none;
    li {
      a {
        .companyCard {
          min-height: 48px;
          width: 100%;
          border-radius: 3px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          border: 1px solid rgb(225, 232, 240);
          p {
            margin-top: 0px;
            margin-bottom: 0px;
            color: #000000;
            opacity: 60%;
          }
          .companyIcon {
            max-width: 40px;
            max-height: 40px;
            margin-right: 20px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .arrowRight {
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
