
.titleContainer {
    h1 {
        font-size: 32px;
        padding-bottom: 16px;
    }
    p, li {
        font-size: 15px;
    }
    ul {
        padding-left: 20px;
        padding-top: 16px;
        li {
            text-align: start;
            color: #767676;

        }
    }
}

.bodyContainer {
    
    margin-top: 20px;

    .nameContainer {
        label {
            display: block;
            color: black;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 4px;
        }
        .inputContainer {
            width: 100%;
            border: #F4F4F4;
            color: rgba($color: #000000, $alpha: 0.2);
            background-color: #F4F4F4;
            padding: 10px;
            border-radius: 3px;
        }
        
    }

    .signatureContainer {
        margin-top: 20px;
        label {
            display: block;
            color: black;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 4px;
        }
        .canvasContainer {
            width: 100%;
            border: #F4F4F4;
            background-color: #F4F4F4;
            padding: 10px;
            border-radius: 3px;
            height: 30vh;
            display: flex;
            
        }
    }
}

.passportContainer {
    padding: 10px 30px;
    margin-top: 20px;
    img {
        width: 100%;
    }

    .passportOptions {
        display: flex;
        justify-content: center;
        align-items: baseline;
        
        p {
            font-size: 14px;
            color: #3BB3E5;
            margin-top: 0.5em;
            margin-bottom: 0;
        }
    }
}
