@font-face {
  font-family: 'Oxygen';
  src: local('Oxygen'), url(./assets/fonts/Oxygen/Oxygen-Regular.ttf) format('ttf');
}

body {
  margin: 0;
  font-family: 'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pincode-input-container .pincode-input-text {
    height: 48px !important;
    width: 48px !important;
    border: 0.5px solid #BABABA !important;
    border-radius: 3px !important;
    margin: 0 8px !important;
    font-size: 32px !important;
    
    
}

.pincode-input-container .pincode-input-text input {
  background-image: linear-gradient(to right, #3BB3E5,   #4EB486) !important;
}

.primaryButton {
  background: #38B4E6;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
}

button:disabled {
  opacity: 50%;
}