.titleContainer {
  h1 {
    font-size: 32px;
  }
  p,
  li {
    font-size: 15px;
  }
  ul {
    padding-left: 20px;
    padding-top: 16px;
    li {
      text-align: start;
      color: #767676;
    }
  }
}

.imageContainer {
  padding: 60px 75px 60px 75px;
  img {
    width: 100%;
  }
}

.bodyContainer {
  padding: 0px 20px 0px 20px;
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  .completedSteps {
    p {
      text-align: center;
      color: #dcdcdc;
      margin-bottom: 24px;
      margin-top: 24px;
    }
  }
}
