.cardGroupContainer {
    margin-bottom: 33px;
    h2 {
        font-size: 21px;
        margin-bottom: 4px;
    }
    p {
        margin-top: 0;
        color: rgb(186, 186, 186);
    }
}