.titleContainer {
    h1 {
        font-size: 32px;
        padding-bottom: 16px;
    }
    p, li {
        font-size: 15px;
    }
    ul {
        padding-left: 20px;
        padding-top: 16px;
        li {
            text-align: start;
            color: #767676;

        }
    }
}

.bodyContainer {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 76px;

    .warningMessage {
        text-align: center;
        font-size: 12px;
        color: #E53B3B;
        padding: 16px 35px;
    }

    .successMessage {
        text-align: center;
        font-size: 12px;
        color: rgb(37, 180, 18);
        padding: 16px 35px;
    }

    .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}