.bodyContainer {
  width: 100%;

  .inputContainer {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .pinInput {
      width: 48px;
      height: 48px;
      font-size: 40px;
      text-align: center;
      outline: none;
      border-radius: 3px;
      border: 0.5px solid #bababa;
      transition-property: color, border, box-shadow, transform;
      transition-duration: 0.25s;
      margin: 0 8px;
    }
  }
}
