.progressContainer {
    margin-top: 53px;
    margin-bottom: 30px;
    height: 3px;
    width: 100%;
    background: rgba(59, 179, 229, 0.5);
    .progress {      
        height: 100%;
        background: rgb(59, 179, 229);
        position:relative;

        .progressLabel {   
            width: 32px;
            height: 25px;
            position: absolute;
            left: 100%;
            transform: translateX(-50%);
            bottom: 100%;
            div {
                position: relative;
                width: 40px;
                span, img {
                    position: absolute;
                }
                img {
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
                span {
                    left: 0;
                    right: 0;
                    text-align: center;
                    color: white;
                }
            }
          
        }
    }
}