.attachmentContainer {
    height: 80px;
    background-color: #F4F4F4;
    border-radius: 3px;
    display: flex;
    padding: 24px 34px;
    justify-content: space-between;
    div {
        display: flex;
        h2 {
            margin-left: 28px;
        }
    }
    
}