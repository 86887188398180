label {
    display: block;
    color: black;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}
.selectContainer {
    width: 100%;
    border: #F4F4F4;
    color: rgba($color: #000000, $alpha: 0.2);
    background-color: #F4F4F4;
    .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}