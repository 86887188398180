.title {
    font-size: 16px;
    line-height: 1.25;
}

.bodyContainer {
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    img {
        width: 64px;
        height: 64px;
    }
    
    p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.6;
        color: #BABABA;
    }
}

.bankSelect {
    padding-left: 20px;
    padding-right: 20px;
    
    label {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 14;
        margin-bottom: 4px;
    }
    .selectContainer {
        width: 100%;
        border: #F4F4F4;
        color: rgba($color: #000000, $alpha: 0.2);
    }
}

.footerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    button {
        min-height: 100%;
        border-radius: 3px;
    }
    .primary {
        width: 55%;
        background: #38B4E6;
        color: white;
        border: 1px solid #38B4E6;
    }
    .secondary {
        width: 40%;
        background: white;
        color: black;
        border: 1px solid black;
    }
}