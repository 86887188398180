.footerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    button {
        min-height: 100%;
        border-radius: 3px;
    }
    .primary {
        width: 55%;
        background: #38B4E6;
        color: white;
        border: 1px solid #38B4E6;
    }
    .secondary {
        width: 40%;
        background: white;
        color: black;
        border: 1px solid black;

    }
}



