.titleContainer {
    h1 {
        font-size: 32px;
        padding-bottom: 16px;
    }
    p, li {
        font-size: 15px;
    }
    ul {
        padding-left: 20px;
        padding-top: 16px;
        li {
            text-align: start;
            color: #767676;
        }
    }
}

.bodyContainer {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;

    .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.passportContainer {
    padding: 10px 30px;
    margin-top: 20px;
    img {
        width: 100%;
    }

    .passportOptions {
        display: flex;
        justify-content: center;
        align-items: baseline;
        
        p {
            font-size: 14px;
            color: #3BB3E5;
            margin-top: 0.5em;
            margin-bottom: 0;
        }
    }
}
