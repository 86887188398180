.titleContainer {
  h1 {
    margin-top: 22px;
    margin-bottom: 8px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bodyContainer {
  padding: 0px 20px 0px 20px;
}
