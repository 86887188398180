h1 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.iconContainer {
    margin-top: 16px;
    margin-bottom: 16px;
}

.bodyContainer {
    padding: 20px;
    margin-top: 20px;

    .infoField {
        margin-bottom: 9px;
        h3 {
            margin-bottom: 0px;
        }

        p {
            color: #DCDCDC;
            margin-bottom: 0px;
        }

        h3:first-letter {
            text-transform: capitalize;
        }
        p:first-letter {
            text-transform: capitalize;
        }
    }
}