a {
    color: #72C9EE;
    text-decoration: none;
    font-size: 16px;
}


.stepsContainer {
    display: flex;
    padding: 20px;
    .stepsNumberContainer {
        display:flex;
        align-items: center;
        justify-content: center;
        background: #C0DE80;
        min-height: 25px;
        min-width: 25px;
        min-height: 25px;
        max-height: 25px;
        padding: 5px;
        border-radius: 50%;
        margin-right: 20px;
        margin-top: 10px;
       
        .stepsNumber {
            color: white;
            font-size: 15px;
        }
        
    }
    .stepsDescriptionContainer {
        .stepsTitle {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 5px;
            
        }
        .stepsDescription {
            color: #DCDCDC;
            font-size: 15px;
        }
    }
}


@media screen and  (min-device-height: 700px) {
    .stepsContainer {
        padding: 15px 20px 15px 20px;    
    }
}

@media screen and  (min-device-height: 800px) {
    .stepsContainer {
        padding: 10px 20px 10px 20px;
        
    }
}
