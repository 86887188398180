.titleContainer {
    h1 {
        font-size: 32px;
        padding-bottom: 16px;
    }
    p, li {
        font-size: 15px;
    }
    ul {
        padding-left: 20px;
        padding-top: 16px;
        li {
            text-align: start;
            color: #767676;

        }
    }
}

.bodyContainer {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 35px;

    .attachment {
        margin-top: 30px;
        
        div:nth-child(1) {
            width: 100%;
        }
        div:nth-child(2) {
            margin-top: 30px;
        }
    }
}